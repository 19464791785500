<template>
  <v-footer color="blue-grey darken-2" dark dense>
      <v-container text-center>
        <v-icon class="mr-2">fas fa-copyright</v-icon>
        <span>{{ footer }}</span>
      </v-container>
    </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footer: {
      type: String,
      default: ""
    }
  }
};
</script>