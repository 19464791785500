import AuthService from '@/service/auth/auth.service'

const BASE_API = process.env.VUE_APP_API_URL;
const rutaFichero = `${BASE_API}ficheros/`;
const rutaImg = `${BASE_API}img/`;

const user = JSON.parse(localStorage.getItem('user'))

const initialState = user
    ? { status : { loggedIn : true }, user,        infoUser: [], roles: "", rutaFichero, rutaImg  }
    : { status : { loggedIn : false }, user: null,  infoUser: [], roles: "", rutaFichero, rutaImg  };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login ( {commit}, user) {
            return AuthService.login(user).then(
            (user) => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            }, (error) => {
                commit('loginFailure')
                return Promise.reject(error);
            });
        },
        validateToken( {commit}) {
            return AuthService.validateToken().then((resp) => {
                // reasigne state auth user
                // commit('loginSuccess', resp.data);
                return Promise.resolve(resp);
            }, (error) => {
                // commit('loginFailure')
                return Promise.reject(error);
            });
        },
        logout( {commit} ){
            AuthService.logout();
            commit('logout')
        },

        register( {commit}, user ){
            return AuthService.register(user).then(
            (response) => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            }, 
            (error) => {
                commit('registerFailure');
                return Promise.reject(error);
            });
        },
        setUser( {commit}, user ){
            commit('setUser', user );
        }
    },
    mutations: {
        loginSuccess(state, user){
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state){
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state){
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state){
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        setUser(state, user ){
            state.infoUser = user;
        }
    }
}
