<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" tile dark>
        <v-app-bar-nav-icon @click.stop="handleAppBar"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
            <span>{{ title }}</span>
        </v-toolbar-title>
        <!-- <v-text-field flat solo-inverted hide-details prepend-inner-icon="mdi-magnify"
        label="Buscar venta" class="hidden-sm-and-down">
        </v-text-field> -->
        <v-spacer></v-spacer>

        <v-btn icon>
            <v-icon>fas fa-bell</v-icon>
        </v-btn>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon to="/controlPanel/profile">
                    <v-icon v-bind="attrs" v-on="on">
                        fas fa-user-circle
                    </v-icon>
                </v-btn>
            </template>
            <span>Perfil</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on" @click.prevent="logout">fas fa-sign-out-alt</v-icon>
            </template>
            <span>Cerrar Sesión</span>
        </v-tooltip>
    </v-app-bar>
</template>
<script>
export default {
    name: "AppBar",
    props: {
        title: {
            type: String,
            default: ""
        },
        toogledrawer: {
            type: Function
        }
    },
    data() {
        return {

        }
    },
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
        },
        handleAppBar() {
            this.toogledrawer();
        }
    }
}

</script>