'use strict'
import Vue from "vue";
import Vuex from "vuex";
import { auth } from './auth.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    layout: 'login-layout'
  },
  modules: {
    auth
  },
  mutations: {
    SET_LAYOUT(state, payload){
      state.layout = payload;
    }
  },
  getters: {
    layout (state) {
      return state.layout
    }
  }
});
