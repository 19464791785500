import { extend } from "vee-validate";
import { required, email, min, max, numeric, min_value, max_value, digits, confirmed, required_if } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Campo requerido, favor de completar"
});

extend("max", {
    ...max,
    message: "Este campo debe ser  {length} caracteres o menos"
});

extend("min", {
    ...min,
    message: "Este campo debe ser  {length} caracteres o mas"
});

extend("email", {
    ...email,
    message: "Este campo debe tener un correo valido"
});

extend("digits", {
    ...digits,
    message: "Este campo debe ser numerico"
});

extend("numeric", {
    ...numeric,
    message: "Este campo debe ser numerico"
});
extend('decimal', {
    validate: value => {
        return /^-?\d*\.?\d*$/.test(value);
    },
    message: "Este campo debe ser numerico"
})
extend("confirmed", {
    ...confirmed,
    message: "Las contraseñas no coinciden"
});
extend("required_if", {
    ...required_if,
    message: "Al menos debe seleccionar una opción"
});
extend("min_value", {
    ...min_value,
    message: "Este campo debe ser mayor o igual a {min}"
})
extend("max_value", {
    ...max_value,
    message: "Este campo debe ser menor o igual a {max}"
})