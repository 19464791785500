import { http, authHeader } from '@/http-commons';
import { auth } from '@/store/auth.module';

class SucursalDataService {

    create( data ){
        return http.post('api/v1/catalogo/sucursal/store', data, { headers : authHeader() });
    }
    show( id ){
        return http.get(`api/v1/catalogo/sucursal/show/${id}`, { headers : authHeader() });
    }
    lstSucursal(){
        return http.get("api/v1/catalogo/sucursal/all", {headers: authHeader() });
    }
    lstSucursalEmpresa(id){
        return http.get(`api/v1/catalogo/sucursal/showByEmpresa/${id}`, {headers: authHeader() });
    }
    update( data ){
        return http.put(`api/v1/catalogo/sucursal/update/${data.id}`, data, { headers: authHeader() });
    }
    delete( id ){
        return http.delete(`api/v1/catalogo/sucursal/delete/${id}`, { headers : authHeader() });
    }
    getallSucursal( params ){
        return http.get('api/v1/catalogo/sucursal/listaSucursal', { params, headers : authHeader() });
    }
    getCurrentSucursal(){
        return auth.state.infoUser?.empleado?.sucursal;
    }
    getCurrentSucursalId(){
        return this.getCurrentSucursal()?.id
    }
}
export default new SucursalDataService();