import axios from "axios";

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.accessToken;
  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, GET, OPTIONS, PATCH, POST, PUT",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  }
});

http.interceptors.request.use(
  config => {
    const token = authHeader();
    if(token){
      config.headers.Authorization = token.Authorization;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(error.response && error.response.status === 401){
      console.error('Error 401: Unauthorized');
      localStorage.removeItem('user');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
)

export { http, authHeader }