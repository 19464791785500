import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL + 'api/v1/auth';
class  AuthService {
    login(user){
        return axios.post(API_URL +'/signin',{
            username : user.username,
            password : user.password
        })
        .then( response => {
            if(response.data.accessToken){
                localStorage.setItem('user',JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    logout(){
        localStorage.removeItem('user');
    }

    register( user ){
        return axios.post(API_URL + '/signup' , {
            username    : user.username,
            email       : user.email,
            password    : user.password
        });
    }

    // validate token
    async validateToken(){
        const user = JSON.parse(localStorage.getItem('user'));
        if(!user || !user.accessToken){
            return Promise.reject();
        }
        const response = await axios.post(API_URL + '/validate-token', {
            token : user.accessToken
        })
        if( response.status === 200){
            return user;
        } else {
            return Promise.reject();
        }
    }

}
export default new AuthService();