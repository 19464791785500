import Vue from "vue";
import App from "./App";
import router from "./router";
import { store } from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "@fortawesome/fontawesome-free/css/all.css";

import { ValidationObserver, ValidationProvider } from "vee-validate";
// import VeeValidate from "vee-validate";
import "./vee-validate";

import vuetify from "@/plugins/vuetify";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";

import "./assets/css/main.css";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(HighchartsVue);
exportingInit(Highcharts);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
