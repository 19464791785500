<template>
    <router-view :layout.sync="layout"/>
</template>
<script>
  export default {
      computed: {
        layout() {
          return this.$store.state.layout;
        }
      }
  }
</script>
