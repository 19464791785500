import { http, authHeader } from '@/http-commons';
import { auth } from '@/store/auth.module';
class UsuarioDataService {
    getProfile( id ) {
        return http.get(`api/v1/usuario/get/${id}`, { headers: authHeader() });
    }
    update( user ) {
        return http.put(`api/v1/usuario/update/${user.id}`, user , { headers : authHeader() });
    }
    /*crud del usuario..*/
    create( user ) {
        return http.post('api/v1/usuario/create', user , { headers: authHeader() });
    }
    getAll( params ) {
        return http.get('api/v1/usuario/table', { params, headers: authHeader() });
    }
    getAllEmpleado(employeeIdToInclude) {
        let route = `api/v1/empleado/allWithoutUser`;

        if (employeeIdToInclude) {
            route += `/${employeeIdToInclude}`;
        }

        return http.get(route, { headers: authHeader() });
    }
    
    jsonUsuario( idUser ) {
        return http.get(`api/v1/usuario/get/${idUser}`, { headers : authHeader() });
    }
    lockUser( id ) {
        return http.delete(`api/v1/usuario/lock/${id}`, null, { headers : authHeader() });
    }
    getAllRoles() {
        return http.get('api/v1/roles/all', { headers : authHeader() });
    }
    getCurrentUser() {
        return auth.state.user;
    }
    getUserRoles(){
        return this.getCurrentUser().roles;
    }

}
export default  new UsuarioDataService();