import Vue from "vue";
import VueRouter from "vue-router";
import LoginLayout from '@/layouts/Login';
import PrincipalLayout from '@/layouts/Principal'
import { auth } from '../store/auth.module';
import { AdminRouter } from "./adminRouter";
Vue.use(VueRouter);
const routes = [
  {
    path: "",
    component: LoginLayout, 
    children: [
      {
        path: "/", 
        component: () => import( /*webpackChunkName: "Login"*/ '@/views/auth/Login.vue'),
        name: "login"
      }
      // { path: "register", component: () => import(/*webpackChunkName: "Register"*/ "@/views/auth/Register.vue"), name: "register" }
    ]
  },
  {
    path: '/controlPanel',
    component: PrincipalLayout,
    children: [
      ...AdminRouter,
      {
        path: 'unauthorized',
        component: () => import('@/views/shared/Unauthorized.vue'),
        name: 'Unauthorized'
      },
      { path: '*', component: () => import('@/views/shared/PageNotFound.vue') }
    ]
  },
];


const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes
});

router.beforeEach( async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // access actions auth when return promise
    try {
      const user = await auth.actions.validateToken('auth/validateToken');
      if( user && user.roles && to.meta.roles.some(role => user.roles.includes(role))){
        next();
      } else {
        next({ name: 'Unauthorized' });
      }
    } catch (error) {
      alert('No tienes permisos para acceder a esta página, favor de iniciar sesión');
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router;